import picAb from "images/team/ab.png";
import picAlex from "images/team/alex.png";
import picAyelet from "images/team/ayelet.png";
import picEliran from "images/team/eliran.png";
import picGal from "images/team/gal.png";
import picGilad from "images/team/gilad.png";
import picIdo from "images/team/ido.png";
import picIsrael from "images/team/israel.png";
import picKoji from "images/team/koji.png";
import picOren from "images/team/oren.png";
import picPj from "images/team/pj.png";
import picSefi from "images/team/sefi.png";


const people = [
  { name: "Eliran Assa", role: "Managing Partner & Co-founder", pic: picEliran },
  { name: "Avner Ziv", role: "Partner & Co-founder", pic: picAb },
  { name: "Ayelet Yamin", role: "Director of Finance", pic: picAyelet },
  { name: "Gal Vital", role: "Director of R&D", pic: picGal },
  { name: "Alex Bahar", role: "Director of Technology", pic: picAlex },
  { name: "Gilad Cohen", role: "Director of Marketing", pic: picGilad },
  { name: "Sefi Bogin", role: "Creative Director", pic: picSefi },
  { name: "Koji Ito", role: "Japan Regional Manager", pic: picKoji },
  { name: "Oren Golan", role: "Director of Operations", pic: picOren },
  { name: "Ido Gat", role: "Director of Legal", pic: picIdo },
  { name: "Israel Yasayeve", role: "Culinary Director", pic: picIsrael },
  { name: "P.J. Mymon", role: "Director of M&A", pic: picPj },
]

export function Members() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
            { people.map((person) => (
              <li key={ person.name }>
                <div className="space-y-6">
                  <img className="mx-auto h-40 rounded-xl xl:h-44 shadow-md" src={ person.pic }
                       alt={ person.name } />
                  <div className="space-y-2">
                    <div className="text-lg leading-6 space-y-1">
                      <h3 className="text-blue-600 font-medium">{ person.name }</h3>
                      <p className="text-gray-600 text-base">{ person.role }</p>
                    </div>
                  </div>
                </div>
              </li>
            )) }
          </ul>
        </div>
      </div>
    </div>
  )
}
