import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import picAnzu from "images/slider/anzu.png";
import picBitcoin from "images/slider/bitcoin.png";
import picDigitalFlare from "images/slider/digital-flare.png";
import picKatif from "images/slider/katif.png";
import picPhenopen from "images/slider/phenopen.png";
import picZota from "images/slider/zotapay.png";
import { Link } from "react-router-dom";

const items = [
  {
    name: "Zotapay",
    pic: picZota,
    text: "Zotapay is a global payment service provider that facilitates online payment processing solutions for emerging markets worldwide, supplying innovative and secure technology.",
  },
  {
    name: "Anzu",
    pic: picAnzu,
    text: "Anzu introduces native, creative, and dynamically-updated advertising to video games and e-sports.",
  },
  {
    name: "PhenoPen",
    pic: picPhenopen,
    text: "PhenoPen is a premium CBD delivery system created by a dedicated team of experts from around the world. PhenoPen is aimed at people who want and need relief from a range of ailments.",
  },
  {
    name: "Bitcoin Change",
    pic: picBitcoin,
    text: "Bitcoin CHANGE is the first store that is facilitating digital assets services that allows buying and selling Bitcoin, Ethereum and other crypto currencies.",
  },
  {
    name: "Digital Flare",
    pic: picDigitalFlare,
    text: "Providing intelligent solutions for sophisticated buying and selling across multi channels devices effortless.",
  },
  {
    name: "Katif",
    pic: picKatif,
    text: "Katif - Stackable Farms.",
  },
];

export function PortfolioSlider() {
  return (
    <div className="hidden md:block relative bg-white overflow-hidden">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-16 sm:pb-16 lg:max-w-4xl lg:pt-16 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 tracking-tight mb-5">Our Portfolio</h2>
        <div className="w-full rounded-lg overflow-hidden">
          <Carousel
            showThumbs={ false }
            useKeyboardArrows={ true }
            autoPlay={ true }
            infiniteLoop={ true }
            showStatus={ false }
          >
            { items.map((item, itemIdx) => (
              <div className="relative" key={ itemIdx }>
                <Link to="/portfolio/">
                  <img src={ item.pic } alt={ item.name } />
                  <p className="absolute text-white w-full px-10 md:px-20 lg:px-40 text-xs md:text-sm lg:text-base"
                     style={ { bottom: 40 } }>
                    <div className="bg-black p-4 rounded-lg bg-opacity-20">
                      { item.text }
                    </div>
                  </p>
                </Link>
              </div>
            )) }
          </Carousel>
        </div>
      </div>
    </div>
  )
}