import picBoard from "images/bubble-board.png";
import picChart from "images/bubble-chart.png";
import picCode from "images/bubble-code.png";
import picColors from "images/bubble-colors.png";
import picSmile from "images/bubble-smile.png";
import picVr from "images/bubble-vr.png";

const sections = [
  {
    title: "From Idea to Execution",
    pic: picVr,
    text: "Our comprehensive and all-encompassing in-house support network helps start-ups to avoid common mistakes that can be made in the initial stages of a new business endeavor. This philosophy assists management to deal only with the core project at hand and to develop their product or service more efficiently without disruption from standard day-to-day business needs.",
  },
  {
    title: "Collaborative Workspace",
    pic: picBoard,
    text: "Various innovative startups work under one roof. Mabsut space provides all of the startup’s needs, from meeting rooms, IT infrastructure to social events! Our approach is to keep your employees productive by providing a high-energy environment.",
  },
  {
    title: "House of Code",
    pic: picCode,
    text: "Our comprehensive and all-encompassing in-house support network helps start-ups to avoid common mistakes that can be made in the initial stages of a new business endeavor. This philosophy assists management to deal only with the core project at hand and to develop their product or service more efficiently without disruption from standard day-to-day business needs.",
  },
  {
    title: "Taking Care of Every Pixel",
    pic: picColors,
    text: "Website design, execution, and delivery are another vital component for the entire of the Mabust Group. SB Digital has years of cutting-edge experience under its belt, delivering a unique visual experience, UE, and UI. The SB Digital team helps to transform dreams into reality, with a keen focus on original digital designs and unique visual experiences.",
  },
  {
    title: "Leave No Lead Behind",
    pic: picChart,
    text: "Website design, execution, and delivery are other vital components for the entire Mabust Group. SB Digital has years of cutting-edge experience under its belt, delivering a unique visual experience, UE, and UI. The SB Digital team helps to transform dreams into reality, with a keen focus on original digital designs and unique visual experiences.",
  },
  {
    title: "Mabsut’s Culture",
    pic: picSmile,
    text: "The Mabsut Group is best described as a group of enthusiastic individuals from different fields and industries, coming together to create a world of fresh and innovative technologies and solutions. The cornerstone of our philosophy is to continually question and re-imagine the digital world experience, for consumers and businesses alike.",
  },
];


export function Sections() {
  return (
    <div className="mx-auto px-4">
      { sections.map((sec, secIdx) => {
        const isEven = secIdx % 2 === 0;
        return (
          <div key={ secIdx }
               className={ `md:flex md:items-center px-10 py-10 sm:px-12 md:py-12 ${ !isEven && "md:flex-row-reverse" }` }>
            <ItemText { ...sec } isEven={ isEven } />
            <ItemPic { ...sec } isEven={ isEven } />
          </div>
        )
      }) }
    </div>
  )
};

const ItemText = ({ title, text, isEven }) => <div className={ `md:w-1/2 ${ isEven ? "sm:pr-10 md:pr-16" : "sm:pl-10 md:pl-16" }` }>
  <div className="font-bold text-xl sm:text-2xl lg:text-3xl text-blue-500 mb-2 lg:mb-4">{ title }</div>
  <div className="text-gray-500">{ text }</div>
</div>


const ItemPic = ({ title, pic, isEven }) => <div className={ `md:w-1/2 pt-10 md:pt-0 ${ isEven && "md:text-right" }` }>
  <img src={ pic } alt={ title } className="inline-flex w-full" />
</div>;
