import { Top } from "pages/what-we-do/Top";
import { Sections } from "pages/what-we-do/Sections";
import { Faq } from "pages/what-we-do/Faq";

export function WhatWeDo() {
  return (
    <div className="py-6 sm:py-10">
      <Top />
      <Sections />
      <Faq />
    </div>
  )
}