import { Top } from "pages/contact/Top";
import { Contact } from "components/Contact";

export function ContactUs() {
  return (
    <div className="py-6 sm:py-10">
      <Top />
      <div className="h-8 sm:h-12"/>
      <Contact />
    </div>
  )
}