import { TopSection } from "pages/home/TopSection";
import { WhoWeAre } from "pages/home/WhoWeAre";
import { Playground } from "pages/home/PlayGround";
import { PortfolioSlider } from "pages/home/PortfolioSlider";
import { WriteToUs } from "components/WriteToUs";

export function Home() {
  return (
    <>
      <TopSection />
      <WhoWeAre />
      <Playground />
      <PortfolioSlider />
      <WriteToUs />
    </>
  )
}