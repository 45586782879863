import { Top } from "pages/about/Top";
import { Sections } from "pages/about/Sections";
import { Locations } from "pages/about/Locations";

export function About() {
  return (
    <div className="py-6 sm:py-10">
      <Top />
      <Sections />
      <Locations />
    </div>
  )
}