import picAnzu from "images/portfolio_anzu.png";
import picBitcoin from "images/portfolio_bitcoin.png";
import picDigitalFlare from "images/portfolio_digital_flare.png";
import picKatif from "images/portfolio_katif.png";
import picPhenopen from "images/portfolio_phenopen.png";
import picZota from "images/portfolio_zota.png";

const items = [
  {
    name: "Zotapay",
    imageUrl: picZota,
    url: "https://www.zotapay.com",
  },
  {
    name: "Anzu",
    imageUrl: picAnzu,
    url: "https://www.anzu.io",
  },
  {
    name: "PhenoPen",
    imageUrl: picPhenopen,
    url: "https://www.phenopen.com",
  },
  {
    name: "Bitcoin Change",
    imageUrl: picBitcoin,
    url: "http://bitcoinchange.co.il",
  },
  {
    name: "Digital Flare",
    imageUrl: picDigitalFlare,
    url: "https://digitalflare.io",
  },
  {
    name: "Katif",
    imageUrl: picKatif,
    url: "https://katif.com",
  },
]

export function Projects() {
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12">
        <div className="space-y-12">
          <ul
            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
            { items.map((item) => (
              <li key={ item.name } className="overflow-hidden rounded-xl">
                <a href={ item.url } target="_blank">
                  <div className="space-y-4">
                    <div className="aspect-w-3 aspect-h-2">
                      <img className="object-cover shadow-lg rounded-lg" src={ item.imageUrl } alt="" />
                    </div>

                    {/*<div className="space-y-2">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3>{ item.name }</h3>
                    </div>
                  </div>*/ }

                  </div>
                </a>
              </li>
            )) }
          </ul>
        </div>
      </div>
    </div>
  )
}
