import svgSofia from "images/sofia.svg";
import svgTokyo from "images/tokyo.svg";
import svgTelAviv from "images/tel-aviv.svg";
import { WriteToUs } from "components/WriteToUs";

export function Locations() {
  return (
    <div className="relative py-8 sm:py-10 mx-auto max-w-7xl">
      <div className="font-bold text-2xl sm:text-3xl text-center text-gray-900">
        Our Locations
      </div>
      <div className="max-w-4xl mx-auto py-6">
        <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3 border">
          <div className="flex flex-col items-center border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
            <dt className="order-2 mt-2 leading-6 text-gray-500">
              Tel Aviv
            </dt>
            <dd className="order-1 text-5xl font-extrabold text-blue-500">
              <img src={svgTelAviv} className="w-16" />
            </dd>
          </div>
          <div className="flex flex-col items-center border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
            <dt className="order-2 mt-2 leading-6 text-gray-500">Sofia</dt>
            <dd className="order-1 text-5xl font-extrabold text-blue-500">
              <img src={svgSofia} className="w-16" />
            </dd>
          </div>
          <div className="flex flex-col items-center border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
            <dt className="order-2 mt-2 leading-6 text-gray-500">Tokyo</dt>
            <dd className="order-1 text-5xl font-extrabold text-blue-500">
              <img src={svgTokyo} className="w-16" />
            </dd>
          </div>
        </dl>
      </div>
      <WriteToUs />
    </div>
  )
}