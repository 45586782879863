import pic from "images/pic-what-we-do.jpg";

export function Top() {
  return (
    <div className="relative">
      <div className="max-w-7xl mx-auto">
        <div className="relative overflow-hidden">
          <div className="absolute inset-0">
            <img
              className="w-full h-full object-cover"
              src={ pic }
              alt="What we do"
            />
            <div className="absolute inset-0 bg-blue-600 mix-blend-multiply opacity-60" />
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              <span className="block text-blue-50">Co-founding <span className="text-white">smart</span></span>
              <span className="block text-blue-50"><span className="text-white">startups</span> together</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}