import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import logo from "images/logo_mabsut_blue.png";
import { Link, useLocation } from "react-router-dom";


const navigation = [
  { name: 'Home', path: '/' },
  { name: 'What We Do', path: '/what-we-do/' },
  { name: 'About Us', path: '/about/' },
  { name: 'Portfolio', path: '/portfolio/' },
  { name: 'Team', path: '/team/' },
  { name: 'Contact', path: '/contact/' },
  /*{ name: 'Careers', path: '/careers/' },*/
];

export function MainMenu({ className }) {
  const location = useLocation();
  return (
    <Popover>
      { ({ open }) => (
        <>
          <div className={ `relative pt-6 px-4 sm:px-6 lg:px-8 ${ className }` }>
            <nav
              className="relative flex items-center justify-between sm:h-10 lg:justify-start"
              aria-label="Global"
            >
              <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <Link to="/">
                    <img
                      className="h-12 w-auto sm:h-14"
                      src={ logo }
                      alt="Mabsut"
                    />
                  </Link>
                  <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-6 whitespace-nowrap">
                { navigation.map((item) => {
                  const active = location.pathname === item.path;
                  return (
                    <Link key={ item.name } to={ item.path }
                          className={ `font-medium ${ active ? "text-blue-700" : "text-gray-500 hover:text-gray-900" }` }>
                      { item.name }
                    </Link>
                  )
                }) }
              </div>
            </nav>
          </div>

          <Transition
            show={ open }
            as={ Fragment }
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50"
            >
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div className="mb-1">
                    <img
                      className="h-10 w-auto"
                      src={ logo }
                      alt=""
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                      <span className="sr-only">Close main menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3 space-y-1">
                  { navigation.map((item) => {
                    const active = location.pathname === item.path;
                    return (
                      <Popover.Button as="div">
                        <Link
                          key={ item.name }
                          to={ item.path }
                          className={ `block px-3 py-2 rounded-md text-base font-medium ${ active ? "text-blue-700" : "text-gray-700 hover:text-gray-900 hover:bg-gray-50" }` }
                        >
                          { item.name }
                        </Link>
                      </Popover.Button>
                    )
                  }) }
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      ) }
    </Popover>
  )
}