import {
  CalculatorIcon,
  CodeIcon, CubeIcon, DatabaseIcon, DesktopComputerIcon,
  LightningBoltIcon,
  PresentationChartLineIcon,
  ScaleIcon, SparklesIcon, SpeakerphoneIcon,
} from '@heroicons/react/outline'

const features = [
  {
    name: "Office Space",
    icon: DesktopComputerIcon,
  },
  {
    name: "Engineering",
    icon: CodeIcon,
  },
  {
    name: "Go To Market",
    icon: PresentationChartLineIcon,
  },
  {
    name: "Data Science",
    icon: DatabaseIcon,
  },
  {
    name: "UI/UX Design",
    icon: SparklesIcon,
  },
  {
    name: "Legal & HR",
    icon: ScaleIcon,
  },
  {
    name: "Product Management",
    icon: CubeIcon,
  },
  {
    name: "Marketing",
    icon: SpeakerphoneIcon,
  },
  {
    name: "Finance",
    icon: CalculatorIcon,
  },
  {
    name: "Operations",
    icon: LightningBoltIcon,
  },
]

export function Playground() {
  return (
    <div className="bg-white">
      <div className="max-w-4xl mx-auto px-4 py-6 sm:px-6 sm:pt-10 sm:pb-16 lg:max-w-7xl lg:pt-16 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 tracking-tight">Mabsut Startup Playground</h2>
        <p className="mt-4 max-w-3xl text-lg text-gray-500">
          We provide our startups all the resources they need in order to grow successfully.
        </p>
        <div
          className="mt-12 grid grid-cols-2 gap-x-6 gap-y-12 lg:mt-16 lg:grid-cols-5 lg:gap-x-8 lg:gap-y-16">
          { features.map((feature) => (
            <div key={ feature.name }>
              <div className="flex items-center justify-center">
                <span className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-400 bg-opacity-10">
                  <feature.icon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-3 text-center">
                <h3 className="font-medium text-blue-700">{ feature.name }</h3>
              </div>
            </div>
          )) }
        </div>
      </div>
    </div>
  )
}
