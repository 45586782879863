import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { WriteToUs } from "components/WriteToUs";

const faqs = [
  {
    question: "How Does Mabsut Work?",
    answer: "We make an investment in each selected startup, and then accelerate outcomes through curated events, workshops, and deep-dives with our team. We leverage our decades of collective experience—and a network that spans across incumbent providers, regulators, counsel, investors, and influencers — to help founders effectively navigate their way through the complexities of financial services.",
  },
  {
    question: "What makes a company ideal for Mabsut?",
    answer: "We’re looking for technology companies in the financial services industry who are launching products that have the ability to transform the industry. When making investments, we prioritize firms that demonstrate a high degree of execution speed, initial product growth and user engagement. Our program is well suited to highly technical teams that need to learn more about some element of the financial services industry to be successful.",
  },
  {
    question: "Why should we work with Mabsut Group?",
    answer: "Early-stage startups see a lot of friction — we’re here to reduce that. We support your goals of innovation and disruption while grounding you research-backed expertise.",
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function Faq() {
  return (
    <div className="relative py-8 sm:py-10 mx-auto max-w-7xl">
      <div className="text-lg sm:text-xl md:text-2xl lg:text-3xl text-center text-blue-500 font-medium">
        “Creativity is thinking up new things.<br />
        Innovation is doing new things.”
        <div className="text-base md:text-lg pt-2 text-gray-400 font-normal">
          Theodore Levitt
        </div>
      </div>
      <div className="mx-auto px-4 sm:px-0 max-w-4xl mb-10">
        <dl className="mt-6 space-y-6 divide-y divide-gray-200">
          { faqs.map((faq) => (
            <Disclosure as="div" key={ faq.question } className="pt-6">
              { ({ open }) => (
                <>
                  <dt className="text-base sm:text-lg">
                    <Disclosure.Button
                      className="text-left w-full flex justify-between items-start text-gray-400 outline-none">
                      <span className="font-medium text-gray-900">{ faq.question }</span>
                      <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={ classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform') }
                            aria-hidden="true"
                          />
                        </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="text-base text-gray-500">{ faq.answer }</p>
                  </Disclosure.Panel>
                </>
              ) }
            </Disclosure>
          )) }
        </dl>
      </div>
      <WriteToUs />
    </div>
  )
}