import { Top } from "pages/portfolio/Top";
import { Projects } from "pages/portfolio/Projects";
import { WriteToUs } from "components/WriteToUs";

export function Portfolio() {
  return (
    <div className="py-6 sm:py-10">
      <Top />
      <Projects />
      <WriteToUs />
    </div>
  )
}