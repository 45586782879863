import picHands from "images/pic_hands.png";
import { Link } from "react-router-dom";

export function WriteToUs() {
  return (
    <div className="relative overflow-hidden">
      <div className="max-w-4xl mx-auto px-4 py-8 sm:px-6 sm:pt-8 sm:pb-8 lg:max-w-7xl lg:pt-8 lg:px-8">
        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8 pb-12">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
            <span className="block"><span className="text-blue-500">Together</span> we can do more.</span>
            <span className="block">Feel free to say <span className="text-blue-500">hello</span>.</span>
          </h2>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/contact/"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-sm sm:text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600"
              >
                Write to us
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center mx-auto -mx-10 -mt-10">
          <img className="w-full" src={ picHands } />
        </div>
      </div>
    </div>
  )
}