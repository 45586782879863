import { Top } from "pages/team/Top";
import { Members } from "pages/team/Members";
import { Contact } from "components/Contact";

export function Team() {
  return (
    <div className="py-6 sm:py-10">
      <Top />
      <Members />
      <Contact />
    </div>
  )
}