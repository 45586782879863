import picLaptops from "images/bubble-laptops.png";
import picCity from "images/bubble-city.png";
import picStatue from "images/bubble-statue.png";
import picNotebooks from "images/bubble-notebooks.png";

const sections = [
  {
    title: "Be Mabsut",
    pic: picLaptops,
    text: "The Mabsut Group is best described as a group of enthusiastic individuals from different fields and industries, coming together to create a world of fresh and innovative technologies and solutions. The cornerstone of our philosophy is to continually question and re-imagine the digital world experience, for consumers and businesses alike.",
  },
  {
    title: "Our Vision",
    pic: picCity,
    text: "The word Mabsut fits perfectly with our worldview and company philosophy, as we help to promote and encourage cutting-edge innovations and ideas with a dedicated focus on investing in people.",
  },
  {
    title: "Our Philosophy",
    pic: picStatue,
    text: "Enables us to focus on the daily activities, atmosphere, and experience of our entrepreneurs and companies, including the members of their team.",
  },
  {
    title: "Our Work",
    pic: picNotebooks,
    text: "Mabsut Group provides a full spectrum of in-house services such as office space, business plan consultation, Internet environment, legal counsel, accounting, design, business development, User Interface, and User Experience. We’re also continually expanding our services, with many exciting new projects in the pipeline for the not too distant future.",
  },
];


export function Sections() {
  return (
    <div className="mx-auto px-4">
      { sections.map((sec, secIdx) => {
        const isEven = secIdx % 2 === 0;
        return (
          <div key={ secIdx }
               className={ `md:flex md:items-center px-10 py-10 sm:px-12 md:py-12 ${ !isEven && "md:flex-row-reverse" }` }>
            <ItemText { ...sec } isEven={ isEven } />
            <ItemPic { ...sec } isEven={ isEven } />
          </div>
        )
      }) }
    </div>
  )
};

const ItemText = ({ title, text, isEven }) => <div
  className={ `md:w-1/2 ${ isEven ? "sm:pr-10 md:pr-16" : "sm:pl-10 md:pl-16" }` }>
  <div className="font-bold text-xl sm:text-2xl lg:text-3xl text-blue-500 mb-2 lg:mb-4">{ title }</div>
  <div className="text-gray-500">{ text }</div>
</div>


const ItemPic = ({ title, pic, isEven }) => <div className={ `md:w-1/2 pt-10 md:pt-0 ${ isEven && "md:text-right" }` }>
  <img src={ pic } alt={ title } className="inline-flex w-full" />
</div>;
