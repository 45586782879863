import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/outline";

export function PageNotFound() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:py-36 sm:px-6 lg:px-8 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-base font-semibold text-blue-600 tracking-wide uppercase">404 - Not Found</h2>
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            This page doesn't exist.
          </p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            <Link to="/" className="text-sm font-medium inline-flex items-center text-blue-600">
              Go Back Home
              <ArrowRightIcon className="w-5 h-5 ml-1" />
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}
