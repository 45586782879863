import { Layout } from "components/Layout";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Home } from "pages/home";
import { WhatWeDo } from "pages/what-we-do";
import { About } from "pages/about";
import { Portfolio } from "pages/portfolio";
import { Team } from "pages/team";
import { ContactUs } from "pages/contact";
import { PageNotFound } from "components/PageNotFound";
import "app.css";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route path="/" exact><Home /></Route>
          <Route path="/what-we-do/" exact><WhatWeDo /></Route>
          <Route path="/about/" exact><About /></Route>
          <Route path="/portfolio/" exact><Portfolio /></Route>
          <Route path="/team/" exact><Team /></Route>
          <Route path="/contact/" exact><ContactUs /></Route>
          <Route path="/*"><PageNotFound /></Route>
          {/*<Route path="/careers/" exact>careers</Route>*/}
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
