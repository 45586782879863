import { Footer } from "components/Footer";
import { MainMenu } from "components/MainMenu";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export function Layout({ children }) {

  const location = useLocation();
  const isHome = location.pathname === "/";

  // scroll to top on route change.
  useEffect(() => window.scrollTo(0,0), [location?.pathname]);

  return (
    <div className="relative overflow-hidden">
      <div className="max-w-7xl mx-auto">
        { !isHome && <MainMenu /> }
        { children }
      </div>
      <Footer />
    </div>
  )
}